<template>
  <div class="testing__info block__content" v-if="test">
    <div class="testing__info__pogress-label d-flex justify-space-between">
      <div>Количество ответов на вопросы</div>
      <div>{{ countSelectQuestion }} из {{ totalQuestion }}</div>
    </div>

    <v-progress-linear
      :color="
        test.Result === null
          ? 'primary'
          : test.Result
          ? 'success lighten-1'
          : 'error lighten-1'
      "
      height="16"
      :value="(countSelectQuestion / totalQuestion) * 100"
      striped
    ></v-progress-linear>
    <div class="testing__info__content">
      <div
        class="testing__info__content__completed"
        v-if="test.Result !== null"
      >
        Тестирование завершено
      </div>
      <div
        class="testing__info__content-ready"
        v-if="countSelectQuestion === totalQuestion && test.Result === null"
      >
        Вы ответили на все вопросы. Завершите тестирование
      </div>
      <div class="testing__info__result" v-if="test.Result !== null">
        <div class="testing__info__result-item success--text text--lighten-1">
          <div class="testing__info__result-label">Правильно</div>
          <div class="testing__info__result-value">
            {{ test.CorrectAnswersCount }}
          </div>
        </div>
        <div class="testing__info__result-item error--text text--lighten-1">
          <div class="testing__info__result-label">Неправильно</div>
          <div class="testing__info__result-value">
            {{ test.IncorrectAnswersCount }}
          </div>
        </div>
        <div class="testing__info__result-item justify-space-between">
          <div class="testing__info__result-label">Не отвечено</div>
          <div class="testing__info__result-value">
            {{
              totalQuestion -
              test.CorrectAnswersCount -
              test.IncorrectAnswersCount
            }}
          </div>
        </div>
        <div class="testing__info__result-item">
          <div class="testing__info__result-label">Время прохождения теста</div>
          <div class="testing__info__result-value">
            {{ test.ElapsedTime && test.ElapsedTime.split(".")[0] }}
          </div>
        </div>
      </div>
    </div>
    <div class="testing__info__time" v-if="test.Result === null">
      <template v-if="test.Start !== null">
        <div class="testing__info__time-label">Оставшееся время:</div>
        <div class="testing__info__time-value">
          {{ test.Time ? duration : "&#x221e;" }}
        </div>
      </template>
      <template v-else>
        <div class="testing__info__time-label">Продолжительность:</div>
        <div class="testing__info__time-value">{{ durationTest }}</div>
      </template>
    </div>
    <div class="testing__info__action text-center">
      <v-btn
        v-if="test.Result === null"
        color="primary"
        dark
        :disabled="countSelectQuestion !== totalQuestion"
        @click="$emit('execute')"
        >Завершить тестирование</v-btn
      >
      <div
        class="testing__info__bad error--text text--lighten-1"
        v-else-if="test.Result === false"
      >
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path
            d="M25.18 12L20 17.18L14.82 12L12 14.82L17.18 20L12 25.18L14.82 28L20 22.82L25.18 28L28 25.18L22.82 20L28 14.82L25.18 12ZM20 0C8.94 0 0 8.94 0 20C0 31.06 8.94 40 20 40C31.06 40 40 31.06 40 20C40 8.94 31.06 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z"
            fill="var(--v-error-lighten1)"
          />
        </svg>
        <span class="ml-3">{{ test.ResultText }}</span>
      </div>

      <div
        v-else-if="test.Result"
        class="testing__info__success success--text text--lighten-1"
      >
        <div class="mb-2">
          Поздравляем,<br />
          Вы успешно сдали тест!
        </div>
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none">
          <path
            d="M53.6654 8.81203L23.2387 39.2654L11.932 27.9587L15.692 24.1987L23.2387 31.7454L49.9054 5.0787L53.6654 8.81203ZM47.772 22.252C48.1187 23.772 48.332 25.372 48.332 26.9987C48.332 38.7854 38.7854 48.332 26.9987 48.332C15.212 48.332 5.66536 38.7854 5.66536 26.9987C5.66536 15.212 15.212 5.66536 26.9987 5.66536C31.212 5.66536 35.1054 6.89203 38.412 8.9987L42.252 5.1587C37.932 2.1187 32.6787 0.332031 26.9987 0.332031C12.2787 0.332031 0.332031 12.2787 0.332031 26.9987C0.332031 41.7187 12.2787 53.6654 26.9987 53.6654C41.7187 53.6654 53.6654 41.7187 53.6654 26.9987C53.6654 23.8254 53.0787 20.7854 52.0654 17.9587L47.772 22.252Z"
            fill="var(--v-success-lighten1)"
          />
        </svg>
      </div>
      <v-btn
        v-if="test.Result === false"
        color="primary"
        class="mt-5"
        dark
        @click="$emit('start', true)"
        >Начать заново</v-btn
      >
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    test: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      timer: 0,
      interval: null,
      attempt: 0,
      currentTime: 0,
    };
  },
  computed: {
    countSelectQuestion() {
      return this.test.QuestionResults?.filter((e) =>
        e.Variants.find((e2) => e2.IsSelected)
      ).length;
    },
    totalQuestion() {
      return this.test.QuestionResults?.length;
    },
    timeLeft() {
      if (this.test.Start) {
        if (this.test.Time === 0) return 99;
        const stopTime = moment(this.test.Start, "DD.MM.YYYY h:mm:ss")
          .add(this.test.Time, "s")
          .unix();

        const duration = moment
          .duration(stopTime - this.currentTime, "seconds")
          .asSeconds();
        return duration;
      }
      return 0;
    },
    duration() {
      let m = moment.duration(this.timeLeft, "s").minutes();
      let s = moment.duration(this.timeLeft, "s").seconds();
      m = m > 9 ? m : "0" + m;
      s = s > 9 ? s : "0" + s;
      return m + ":" + s;
    },
    durationTest() {
      const d = moment.duration(this.test.Time, "s");

      return [d.minutes(), d.seconds()]
        .map((e) => (e > 9 ? e : "0" + e))
        .join(":");
    },
  },
  watch: {
    timeLeft(val) {
      if (val <= 0 && this.test.Result === null && this.test.Time !== 0) {
        this.$emit("execute");
      }
    },
    "test.CurrentTime": {
      immediate: true,
      handler() {
        this.currentTime = moment(
          this.test.CurrentTime,
          "DD.MM.YYYY h:mm:ss"
        ).unix();
      },
    },
    "test.Start": {
      immediate: true,
      handler() {
        if (this.test.Start && this.test.Result === null) {
          clearInterval(this.interval);

          this.interval = setInterval(() => {
            if (this.timeLeft <= 0) {
              clearInterval(this.interval);
            }
            this.currentTime++;
          }, 1000);
        }
      },
    },
  },
};
</script>
<style lang="scss">
.testing {
  &__info {
    width: 446px;
    height: 470px;
    padding: 20px 31px;
    &__pogress-label {
      margin: 18px 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
    }
    &__content {
      height: 125px;
      &-ready {
        padding: 48px 60px;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: var(--v-primary-base);
      }
      &__completed {
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: var(--v-text-base);
        margin-top: 25px;
        margin-bottom: 30px;
      }
    }
    &__time {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      color: var(--v-text-base);

      &-label {
        align-self: center;
        font-size: 14px;
        line-height: 19px;
      }
      &-value {
        font-size: 36px;
        line-height: 49px;
      }
    }
    &__action {
      margin-top: 58px;
    }

    &__result {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: var(--v-text-base);
      &-item {
        display: flex;
        justify-content: space-between;
      }
      &-item:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    &__success {
      margin-top: 90px;
    }
    &__bad {
      white-space: pre-line;
      margin-top: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
